// import classNames from 'classnames/bind';
// import styles from './ButtonLink.module.scss';
import { NavLink } from 'react-router-dom';

// const cn = classNames.bind(styles);

const ButtonLink = ({ link, func, children }) => {
	return (
		<NavLink
			to={link}
			className="btn btn-lg btn-primary"
			onClick={() => func()}
		>
			{children}
		</NavLink>
	)
}

export default ButtonLink;
