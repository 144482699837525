import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import Cookies from 'js-cookie';

const url = process.env.REACT_APP_SERVER_URL;

export const fetchUserHabits = createAsyncThunk(
	'habits/fetchUserHabits',
	async function (_, { dispatch }) {
			const id = Cookies.get('uuid')
			const res = await axios.get(`${url}/habits/${id}`);

			if (res.statusText === 'OK' && Array.isArray(res.data.bad_habits)) {
				dispatch(addToBadHabits(res.data.bad_habits));
			}
			if (res.statusText === 'OK' && Array.isArray(res.data.good_habits)) {
				dispatch(addToGoodHabits(res.data.good_habits));
			}
	}
);

export const fetchAllUserHabits = createAsyncThunk(
	'habits/fetchAllUserHabits',
	async function (_, { dispatch }) {
			const res = await axios.get(`${url}/habits`);

			if (res.statusText === 'OK') {
				dispatch(addToAllHabits(res.data));
			}
	}
);

export const addGoodHabits = createAsyncThunk(
	'habits/addGoodHabits',
	async function (goodHabits, { dispatch }) {
		const headers = {
			'Content-Type': 'application/json',
			Accept: 'application/json',
		};
		const id = Cookies.get('uuid');
		const payload = {goodHabits}
		const res = await axios.patch(`${url}/good-habits/${id}`, payload, { headers });

		if (res.statusText === 'OK') {
			dispatch(addToGoodHabits(JSON.parse(goodHabits)));
		}
	}
);

export const addBadHabits = createAsyncThunk(
	'habits/addBadHabits',
	async function (badHabits, { dispatch }) {
			const headers = {
				'Content-Type': 'application/json',
				Accept: 'application/json',
			};
			const res = await axios.post(`${url}/bad-habits`, badHabits, { headers });

			if (res.statusText === 'OK') {
				dispatch(addToBadHabits(JSON.parse(badHabits.get('badHabits'))));
			}
	}
);

export const updateBadHabits = createAsyncThunk(
	'habits/updateBadHabits',
	async function (badHabits, { dispatch }) {
			const headers = {
				'Content-Type': 'application/json',
				Accept: 'application/json',
			};
			const id = Cookies.get('uuid');

			const res = await axios.patch(`${url}/bad-habits/${id}`, badHabits, { headers });

			if (res.statusText === 'OK') {
				dispatch(addToBadHabits(JSON.parse(badHabits.get('badHabits'))));
			}
	}
);

export const setRating = createAsyncThunk(
	'habits/setRating',
	async function (rating) {
		const headers = {
			'Content-Type': 'application/json',
			Accept: 'application/json',
		};
		const id = Cookies.get('uuid');
		const payload = { rating };
		await axios.patch(`${url}/rating/${id}`, payload, { headers });
	}
);

export const counterSlice = createSlice({
	name: 'habits',
	initialState: {
		badHabits: [],
		goodHabits: [],
		allUsersHabits: {},
	},
	reducers: {
		addToBadHabits: (state, action) => {
			state.badHabits = [...action.payload];
		},
		addToGoodHabits: (state, action) => {
			state.goodHabits = [...action.payload];
		},
		addToAllHabits: (state, action) => {
			state.allUsersHabits = {...action.payload};
		},
	},
});

export const { addToBadHabits, addToGoodHabits, addToAllHabits } = counterSlice.actions;

export default counterSlice.reducer;
