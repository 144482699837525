import { useState, useEffect, useMemo } from 'react';
import { goodHabits, badHabits } from '../../src/data/habits';
import { useSelector } from 'react-redux';

export const useDragAndDrop = ({ type }) => {
	const [habitsList, setHabitsList] = useState([]);
	const [habitsDropPlace, setHabitsDropPlace] = useState([]);

	const badStoreHabits = useSelector((state) => state.habits.badHabits);
	const goodStoreHabits = useSelector((state) => state.habits.goodHabits);

	useEffect(() => {
		if (type === 'good-habits') {
			const shuffledHabits = shuffleHabits(goodHabits);
			setHabitsList(shuffledHabits);
			setHabitsDropPlace(goodStoreHabits);
		}
		if (type === 'bad-habits') {
			const shuffledHabits = shuffleHabits(badHabits);
			setHabitsList(shuffledHabits);
			setHabitsDropPlace(badStoreHabits);
		}
	}, [type]);

	useMemo(() => {
		let habitsListCopy = [...habitsList];
		let habitsDropPlaceCopy = [...habitsDropPlace];
		habitsListCopy.forEach((i) => {
			habitsDropPlaceCopy.forEach((j) => {
				if (j.id === i.id) i.checked = true;
			});
		});
		setHabitsList(habitsListCopy);
	}, [habitsDropPlace]);

	const handleOnDragEnd = ({ source, destination }) => {
		if (habitsDropPlace.length === 7 && source.droppableId === 'habitsList')
			return;
		if (!destination) return;

		const getList = (list) =>
			list === 'habitsList' ? habitsList : habitsDropPlace;

		if (source.droppableId === destination.droppableId) {
			const items = [...getList(source.droppableId)];
			const [removed] = items.splice(source.index, 1);
			items.splice(destination.index, 0, removed);

			if (source.droppableId === 'habitsList') setHabitsList(items);
			if (source.droppableId === 'habitsDropPlace')
				setHabitsDropPlace(items);
		} else {
			const destArrayClone = [...getList(destination.droppableId)];
			const [removed] = [...getList(source.droppableId)].slice(
				source.index,
				source.index + 1
			);
			removed.checked = true;
			destArrayClone.push(removed);
			setHabitsDropPlace(destArrayClone);
		}
	};

	const handleOnSwipe = (item) => {
		if (!item.checked) {
			item.checked = true;
			const habitsDropPlaceClone = [...habitsDropPlace];
			habitsDropPlaceClone.push(item);
			setHabitsDropPlace(habitsDropPlaceClone);
		}
		else if (item.checked) {
			delete item.checked;
			returnHabitToList(item);
		}
	};

	const shuffleHabits = (array) => {
		return array
			.map((value) => ({ value, sort: Math.random() }))
			.sort((a, b) => a.sort - b.sort)
			.map(({ value }) => value);
	};

	const returnHabitToList = (item) => {
		const filteredDropPlace = habitsDropPlace.filter((i) => i.id !== item.id);
		setHabitsDropPlace(filteredDropPlace);
	};

	return {
		handleOnDragEnd,
		habitsDropPlace,
		habitsList,
		returnHabitToList,
		setHabitsDropPlace,
		handleOnSwipe,
	};
};
