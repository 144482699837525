import classNames from 'classnames/bind';
import styles from './Rating.module.scss';
import { useState, useEffect } from 'react';
import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { fetchAllUserHabits } from '../../store/habitsSlice';
import { NavLink } from 'react-router-dom';
import BootstrapModal from '../../components/_UI/BootstrapModal/BootstrapModal';
import logo from '../../assets/img/logo.svg';

const cn = classNames.bind(styles);

const Rating = () => {
	const [modalShow, setModalShow] = useState(false);

	const { sortedHabits } = useSelector((state) => state.habits.allUsersHabits);

	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(fetchAllUserHabits());
		setTimeout(() => {
			setModalShow(true);
		}, 3000);
	}, []);

	return (
		<>
			<BootstrapModal
				show={modalShow}
				onHide={() => setModalShow(false)}
				type='rating'
			/>

			<div className="main-wrapper">
				<div className={cn('background-wrapper')}>
					<div className={cn('background')}
					></div>
				</div>

				<div className="container-xl position-fixed end-0 start-0 z-100">
					<NavLink to="/" className={cn('logo')}>
						<img
							src={logo}
							alt=""
						/>
					</NavLink>
					<div className="position-absolute top-0 end-0 mt-2">
						{/* <NavLink to='/rating/' className='btn btn-primary text-nowrap me-2'>Рейтинг</NavLink> */}
						<NavLink to="/" className='btn btn-primary text-nowrap me-2'>Начать заново</NavLink>
					</div>
				</div>

				<div className={cn('main-container', 'container-xl position-relative')}>
					<div className={cn('row g-0 z-1', 'row-header')}>
						<div className={cn('stars-wrapper')}></div>
					</div>
					<div className={cn('row', 'row-main')}>
						<div className={cn('left-side', 'col-xl-6')}>
							<div className='shadow w-100 mb-5'>
								<h2 className={cn('habits-header-bad')}>Вредные привычки</h2>
								<div className={cn('habits-block')}>
									{sortedHabits?.bad_habits.map(el =>
										<div className={cn('habit-container')} key={el.text}>
											<span>
												{el.text}
											</span>
											<span>
												{`${el.percent}%`}
											</span>
										</div>
									)}
								</div>
							</div>
						</div>
						<div className={cn('right-side', 'col-xl-6')}>
							<div className='shadow w-100 mb-5'>
								<h2 className={cn('habits-header-good')}>Полезные привычки</h2>
								<div className={cn('habits-block')}>
									{sortedHabits?.good_habits.map(el =>
										<div className={cn('habit-container')} key={el.text}>
											<span>
												{el.text}
											</span>
											<span>
												{`${el.percent}%`}
											</span>
										</div>
									)}
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	)
}

export default Rating;
