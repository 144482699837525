import Cookies from 'js-cookie';
import uuid from 'react-uuid';
import axios from 'axios';


const badHabitsCreateObject = async (chosenHabits) => {
		const uuId = uuid();
		Cookies.set('uuid', uuId);

		const { data } = await axios.get('https://geolocation-db.com/json/');

		const formData = new FormData();
		formData.append('id', uuId);
		formData.append('badHabits', JSON.stringify(chosenHabits));
		formData.append('date', new Date().toLocaleString());
		formData.append('ip', data.IPv4);

		return formData;
};

const badHabitsUpdateObject = async (chosenHabits) => {
		const formData = new FormData();
		formData.append('badHabits', JSON.stringify(chosenHabits));

		return formData;
};

const ratingChanged = async (newRating) => {
	const id = Cookies.get('uuid');
	await axios.put(`http://localhost:8800/rating/${id}`, {
		rating: newRating,
	});
};

export { badHabitsCreateObject, badHabitsUpdateObject, ratingChanged };
