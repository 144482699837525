import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

export const useAllHabits = () => {
	const [allHabits, setAllHabits] = useState([]);

	const badHabits = useSelector((state) => state.habits.badHabits);
	const goodHabits = useSelector((state) => state.habits.goodHabits);

	useEffect(() => {
		setAllHabits([...badHabits, ...goodHabits]);
	}, [badHabits, goodHabits]);

	return { allHabits };
};
