import classNames from 'classnames/bind';
import styles from './Modal.module.scss';
import { useDispatch } from 'react-redux';
import { addBadHabits, addGoodHabits, updateBadHabits } from '../../../store/habitsSlice';
import ButtonLink from '../ButtonLink/ButtonLink';
import { modalWindowText } from '../../../data/siteText';
import { badHabitsCreateObject, badHabitsUpdateObject } from '../../../helpers/serverRequests';
import Cookies from 'js-cookie';

const cn = classNames.bind(styles);

const Modal = ({ type, habitsDropPlace }) => {

	const dispatch = useDispatch();

	const nextStep = async () => {
		if (type === 'bad-habits' && !Cookies.get('uuid')) {
			const badHabits = await badHabitsCreateObject(habitsDropPlace);
			dispatch(addBadHabits(badHabits));
		}
		if (type === 'bad-habits' && Cookies.get('uuid')) {
			const badHabits = await badHabitsUpdateObject(habitsDropPlace);
			dispatch(updateBadHabits(badHabits));
		}
		if (type === 'good-habits') {
			dispatch(addGoodHabits(JSON.stringify(habitsDropPlace)));
		}
	};

	return (
		<div className={cn('modal-window', 'container')}>
			<div className={cn('modal-container', 'modal-dialog modal-lg')}>
				<p className={cn('modal-window-text', 'fs-4 mb-5')}>
					{type === 'good-habits' && modalWindowText.goodHabitsText}
					{type === 'bad-habits' && modalWindowText.badHabitsText}
				</p>
				<ButtonLink
					link={type === 'bad-habits' ? '/good-habits/' : '/chosen-habits/'}
					func={nextStep}
				>
					Далее
				</ButtonLink>
			</div>
		</div>
	)
}

export default Modal
