import html2pdf from 'html2pdf.js/src';
import validator from 'validator';
import axios from 'axios';

const address = process.env.REACT_APP_EMAIL_API;

export const usePDF = (email, setEmail, setEmailError, setModalShow) => {
	const generatePDF = () => {
		const element = document.getElementById('pdf-content');
		const opt = {
			margin: [0, 0, 0, 0],
			filename: 'document.pdf',
			image: { type: 'jpeg', quality: 1 },
			html2canvas: { scale: 1 },
			jsPDF: { unit: 'px', format: [1127, 797], orientation: 'landscape' },
		};
		const res = html2pdf().set(opt).from(element).output('datauristring');

		return res;
	};

	const savePdf = () => {
		const element = document.getElementById('pdf-content');
		const opt = {
			margin: [0, 0, 0, 0],
			filename: 'document.pdf',
			image: { type: 'jpeg', quality: 1 },
			html2canvas: { scale: 1 },
			jsPDF: { unit: 'px', format: [1127, 797], orientation: 'landscape' },
		};
		const res = html2pdf().set(opt).from(element).save();
		return res
	}

	const sendPDF = async () => {
		if (validator.isEmail(email)) {
			window._mtm = window._mtm || [];
			window._mtm.push({'event': 'formSubmit', 'formType': 'Отправка результатов на почту и Дед Морозу' });
			const pdf = await generatePDF();
			setEmailError('');
			await axios.post(address, {
				content: 'PDF',
				email,
				pdf_content: pdf.split(',')[1],
			});
			setEmail('');
			setModalShow(true);
		} else {
			setEmailError('Введите валидный e-mail');
		}
	};

	return {
		sendPDF,
		generatePDF,
		savePdf,
	};
};
