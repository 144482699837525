export const toysPosition = [
	// {
	// 	id: '1',
	// 	styles: {
	// 		width: '8%',
	// 		maxWidth: '50px',
	// 		position: 'absolute',
	// 		top: '17%',
	// 		left: '47%',
	// 	},
	// },
	// {
	// 	id: '2',
	// 	styles: {
	// 		width: '8%',
	// 		maxWidth: '50px',
	// 		position: 'absolute',
	// 		top: '25%',
	// 		left: '55%',
	// 	},
	// },
	{
		id: '3',
		styles: {
			width: '8%',
			maxWidth: '50px',
			position: 'absolute',
			top: '37%',
			left: '37%',
		},
	},
	{
		id: '4',
		styles: {
			width: '8%',
			maxWidth: '50px',
			position: 'absolute',
			top: '26%',
			left: '52%',
		},
	},
	{
		id: '5',
		styles: {
			width: '8%',
			maxWidth: '50px',
			position: 'absolute',
			top: '54%',
			left: '26%',
		},
	},
	{
		id: '6',
		styles: {
			width: '8%',
			maxWidth: '50px',
			position: 'absolute',
			top: '53%',
			left: '54%',
		},
	},
	// {
	// 	id: '7',
	// 	styles: {
	// 		width: '8%',
	// 		maxWidth: '50px',
	// 		position: 'absolute',
	// 		top: '54%',
	// 		left: '52%',
	// 	},
	// },
	{
		id: '8',
		styles: {
			width: '8%',
			maxWidth: '50px',
			position: 'absolute',
			top: '75%',
			left: '51%',
		},
	},
	{
		id: '9',
		styles: {
			width: '8%',
			maxWidth: '50px',
			position: 'absolute',
			top: '66%',
			left: '22%',
		},
	},
	{
		id: '10',
		styles: {
			width: '8%',
			maxWidth: '50px',
			position: 'absolute',
			top: '74%',
			left: '77%',
		},
	},
];
