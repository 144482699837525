export const startPageText = {
	title: 'Наряжаем елку полезных финансовых привычек!',
	firstParagraph:
		'Оцените, какие привычки характеризуют вас, но вы бы хотели с ними расстаться? Давайте отправим их в камин, там им самое место! Оставим их в уходящем году.',
	secondParagraph:
		'Какие новые привычки вы бы хотели приобрести в Новом году или пожелать себе? Нарядим ими нашу новогоднюю елку!',
	thirdParagraph:
		'Итоговый чек-лист Вы сможете сохранить и пользоваться им в Новом году. А в разделе «Рейтинг» можно будет узнать, какие цели поставили себе другие финЗОЖники.',
};

export const chooseHabitsText = {
	goodHabitsTitle: 'Наряжаем елку полезных привычек',
	badHabitsTitle: 'Сжигаем вредные финансовые привычки',
	goodHabitsDesctopDescription:
		'<p>Перетащите на елку полезные финансовые привычки, которые вы хотели бы приобрести в Новом году.</p><div><img class="pic-desctop" id="desc-pic" alt="" /></div><p>Каждая из них сделает серый шарик цветным.</p><p>Когда все 7 шариков на елке окрасятся, можно перейти на следующий шаг.</p>',
	badHabitsDesctopDescription:
		'<p>Перетащите в камин негативно влияющие на финансы привычки, которые вы хотите оставить в уходящем году.</p><div><img class="pic-desctop" id="desc-pic" alt="" /></div><p>Вы можете вернуть привычку в список, если передумали.</p><p>Следующий шаг будет доступен, когда в камине окажутся 7 вредных привычек.</p>',
	goodHabitsMobileDescription:
		'<p>Повесьте на елку полезные финансовые привычки, которые вы хотели бы приобрести в Новом году. Для этого свайпайте их влево.</p><img class="pic-mobile" id="mobile-pic" alt="" /><p>Каждая из них сделает серый шарик цветным.</p><p>Когда все 7 шариков на елке окрасятся, можно перейти на следующий шаг.</p>',
	badHabitsMobileDescription:
		'<p>Отправьте в камин негативно влияющие на финансы привычки, которые вы хотите оставить в уходящем году. Для этого свайпайте их влево.</p><img class="pic-mobile" id="mobile-pic" alt="" /><p>Вы можете вернуть привычку в список, если передумали.</p><p>Следующий шаг будет доступен, когда в камине окажутся 7 вредных привычек.</p>',
};

export const modalWindowText = {
	goodHabitsText:
		'Супер! Теперь у нас есть 7 полезных привычек, которые мы возьмем в Новый год. На следующем шаге сформируем чек-лист.',
	badHabitsText:
		'Ура! Вы отправили в камин 7 вредных финансовых привычек. Теперь можно перейти к следующему шагу',
	chosenHabitsText:
		'Отправьте результат себе на почту. Копия автоматически отправится Дедушке Морозу!',
};

export const chosenHabitsText = {
	title: 'Мои финЗОЖ цели на 2023 год',
	desc: 'Давайте посмотрим, над чем нужно будет поработать в Новом году.',
};

export const ratingText = {
	text: 'Спасибо, мы приняли Вашу оценку!'
}

export const emailModalText = {
	text: 'Спасибо! Мы отправили e-mail на указанный адрес.',
	modalText: '<p>Какие цели поставили себе другие финЗОЖники?</p><p>Узнайте, нажав на кнопку «Рейтинг»</p>',
}
