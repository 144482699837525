import { useState, useEffect } from 'react';
import coloredToy from '../assets/img/tree-toy.svg';
import uncoloredToy from '../assets/img/tree-toy-uncolored.svg';
import { toysPosition } from '../data/treeToys';

export const useToysAndFlameState = ({ type, habitsDropPlace }) => {
	const [toys, setToys] = useState([]);
	const [coloredToysAmount, setColoredToysAmount] = useState(0);
	const [flash, setFlash] = useState(false);
	const [burnedHabits, setBurnedHabits] = useState(0);

	useEffect(() => {
		if (type === 'bad-habits') {
			setBurnedHabits(habitsDropPlace?.length);
		}
		if (type === 'bad-habits' && burnedHabits < habitsDropPlace?.length) {
			setFlash(true);
			setTimeout(() => {
				setFlash(false);
			}, 1000);
		}
	}, [habitsDropPlace]);

	useEffect(() => {
		if (type === 'good-habits' && !habitsDropPlace.length) {
			const toysArr = toysPosition.map((item) => ({
				...item,
				treeToy: uncoloredToy,
			}));
			setToys(toysArr);
		}
	}, [type, habitsDropPlace]);

	useEffect(() => {
		if (
			type === 'good-habits' &&
			toys.length &&
			habitsDropPlace?.length > coloredToysAmount
		) {
			const updatedToysArr = toys.map((item, idx) => {
				if (idx + 1 === habitsDropPlace?.length)
					return { ...item, treeToy: coloredToy };
				return item;
			});
			setToys(updatedToysArr);
			setColoredToysAmount(habitsDropPlace?.length);
		}
		if (
			type === 'good-habits' &&
			toys.length &&
			habitsDropPlace?.length < coloredToysAmount
		) {
			const updatedToysArr = toys.map((item, idx) => {
				if (idx === habitsDropPlace?.length)
					return { ...item, treeToy: uncoloredToy };
				return item;
			});
			setToys(updatedToysArr);
			setColoredToysAmount(habitsDropPlace?.length);
		}
		if (
			type === 'good-habits' &&
			coloredToysAmount === 0 &&
			habitsDropPlace.length === 7
		) {
			setColoredToysAmount(habitsDropPlace.length);
			const toysArr = toysPosition.map((item) => ({
				...item,
				treeToy: coloredToy,
			}));
			setToys(toysArr);
		}
	}, [habitsDropPlace]);

	return { toys, flash };
};
