import classNames from 'classnames/bind';
import styles from './Input.module.scss';
import { useState } from 'react';
import { usePDF } from './../../../hooks/usePDF';

const cn = classNames.bind(styles);

const Input = ({ setModalShow }) => {
	const [email, setEmail] = useState('');
	const [emailError, setEmailError] = useState('');

	const { sendPDF } = usePDF(email, setEmail, setEmailError, setModalShow);

	return (
		<div>
			<div className="input-group mb-3">
				<input
					type="email"
					name="email"
					value={email}
					className="form-control"
					placeholder="Введите e-mail"
					onChange={(e) => setEmail(e.target.value)}
				/>				
				<button
					onClick={() => sendPDF()}
					className="btn btn-primary"
				>
					Отправить
				</button>
			</div>
			<div
				className={cn('error-message')}
			>
				{emailError}
			</div>
		</div>
	)
}

export default Input
