import { useEffect, useState } from "react"
import classNames from 'classnames/bind';
import styles from './Chart.module.scss';
import ReactFC from "react-fusioncharts"
import FusionCharts from "fusioncharts"
import PowerCharts from "fusioncharts/fusioncharts.powercharts"
import FusionTheme from "fusioncharts/themes/fusioncharts.theme.fusion"
// import { links, nodes } from '../../data/chartData'
import { useSelector, useDispatch } from 'react-redux';
import { fetchAllUserHabits } from '../../store/habitsSlice';

const cn = classNames.bind(styles);

ReactFC.fcRoot(FusionCharts, PowerCharts, FusionTheme)

const mainNode = [
	{
		label: 'Ответы пользователей',
		color: '#E9DA0F',
		rotateLabel: 1,
		showValue: 0,
		showLabel: 0,
		toolText: '$label',
	},
];

const Chart = () => {
	const [links, setLinkss] = useState([])
	const [nodes, setNodess] = useState([])
	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(fetchAllUserHabits());
	}, []);

	const { sortedHabits, usersAmount } = useSelector((state) => state.habits.allUsersHabits);

	const badLinks = sortedHabits?.bad_habits.map((el) => ({
		from: el.text,
		to: 'Ответы пользователей',
		value: el.percent,
	}))
	const goodLinks = sortedHabits?.good_habits.map((el) => ({
		from: 'Ответы пользователей',
		to: el.text,
		value: el.percent,
		color: '#69E949',
		toolText: '$toPlotIdentifier $to - $linkDataValue',
	}))

	const setNodesArray = (items) =>
		items?.map((el) => {
			if (el.from === 'Ответы пользователей') {
				return {
					label: el.to,
					color: '#69E949',
					labelPosition: 'start',
				};
			} else {
				return {
					label: el.from,
					color: '#E90B12',
					labelPosition: 'end',
				};
			}
		});

	useEffect(() => {
		if (sortedHabits?.bad_habits.length && sortedHabits?.good_habits.length) {
			setLinkss([...badLinks, ...goodLinks]);
			setNodess([
				...setNodesArray(badLinks),
				...setNodesArray(goodLinks),
				...mainNode,
			])
		}
	}, [sortedHabits?.bad_habits, sortedHabits?.good_habits])

	const chartConfigs = {
		type: "sankey",
		width: "100%",
		height: "600",
		dataFormat: "json",
		dataSource: {
			chart: {
				// caption: "Елка финансовых привычек",
				// subcaption: "Вредные привычки | Полезные привычки",
				showLegend: 0,
				theme: "fusion",
				animation: 0,
				nodeWidth: 35,
				nodeRelaxation: 1,
				numberSuffix: '%',
				plotToolText: '$label - $nodeDataValue',
				linkToolText: '$fromPlotIdentifier $from - $linkDataValue',
				linkCurvature: 0,
				highlightEffect: 1,
				// nodeLabelPadding: '-10'
				showNodeValues: 1,
				nodeLabelFontBold: 1
			},
			nodes,
			links,
		}
	};

	return (
		<div className={cn('wrapper')}>
			<h2>Елка финансовых привычек</h2>
			<div>Всего проголосовало {usersAmount} человек</div>
			<div className="d-flex justify-content-between">
				<span>Вредные привычки</span>
				<span>Полезные привычки</span>
			</div>
			<ReactFC {...chartConfigs} />
		</div>
	);
}

export default Chart