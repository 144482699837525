import classNames from 'classnames/bind';
import styles from './DropArea.module.scss';
import { Droppable, Draggable } from 'react-beautiful-dnd';

const cn = classNames.bind(styles);

const DropArea = ({ droppableId, dropableClass, habitsList, listClass, icon, returnHabitToList }) => {

	const returnHabitHandler = (item) => {
		if (item.checked) {
			delete item.checked;
			returnHabitToList(item);
		}
	};

	return (
		<div className={cn(dropableClass, 'wrapper')}>
			<Droppable droppableId={droppableId}>
				{(provided) => (
					<ul className={cn(listClass, 'test')} {...provided.droppableProps} ref={provided.innerRef}>
						{habitsList?.map((item, index) => {
							return (
								<Draggable key={item.id} draggableId={item.id} index={index} isDragDisabled={item.checked}>
									{(provided) => (
										<li
											className={cn('habit', {
												'chosen-habit': item.checked
											})}
											ref={provided.innerRef}
											{...provided.draggableProps}
											{...provided.dragHandleProps}
											onClick={() => returnHabitHandler(item)}
										>
											{!item.checked &&
												<>
													<img src={icon} alt="" />
													<p className={cn('habit-text')}>
													{item.text}
													</p>
												</>
											}
											{item.checked &&
												<>
													<p className={cn('habit-text')}>
													{item.text}
													</p>
													<span className={cn('habit-link')}>Вернуть в список</span>
												</>
											}
										</li>
									)}
								</Draggable>
							)
						}
						)}
						{provided.placeholder}
					</ul>
				)}
			</Droppable>
		</div>
	)
}

export default DropArea;
