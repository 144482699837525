import { useState, useEffect } from 'react';
import classNames from 'classnames/bind';
import styles from './ChooseHabitsPage.module.scss';
import { DragDropContext } from 'react-beautiful-dnd';
import ball from '../../assets/img/tree-toy.svg';
import trash from '../../assets/img/bad_habbit_icon.png';
import firePlace from '../../assets/img/firePlace.svg';
import logo from '../../assets/img/logo.svg';
import christmasTree from '../../assets/img/nyTree.png';
import greenToy from '../../assets/img/green-toy.svg';
import { useDragAndDrop } from '../../hooks/useDragAndDrop';
import { useToysAndFlameState } from './../../hooks/useToysAndFlameState';
import DropArea from '../../components/DropArea/DropArea';
import Modal from '../../components/_UI/Modal/Modal';
import Flame from '../../components/_UI/Flame/Flame';
import BootstrapModal from '../../components/_UI/BootstrapModal/BootstrapModal';
import { useSelector } from 'react-redux';
import { chooseHabitsText } from './../../data/siteText';
import { NavLink } from "react-router-dom";
import SwipeableViews from 'react-swipeable-views';

const cn = classNames.bind(styles);

const ChooseHabitsPage = ({ type }) => {
	const [modalShow, setModalShow] = useState(false);
	const [visiable, setVisiable] = useState(false);

	const {
		handleOnDragEnd,
		habitsDropPlace,
		habitsList,
		returnHabitToList,
		setHabitsDropPlace,
		handleOnSwipe,
	} = useDragAndDrop({ type });

	const { toys, flash } = useToysAndFlameState({ type, habitsDropPlace });

	const badHabits = useSelector((state) => state.habits.badHabits);
	const goodHabits = useSelector((state) => state.habits.goodHabits);

	useEffect(() => {
		if (type === 'good-habits') setHabitsDropPlace(goodHabits);
		if (type === 'bad-habits') setHabitsDropPlace(badHabits);
	}, [goodHabits, badHabits]);

	useEffect(() => {
		setTimeout(() => {
			setModalShow(true);
		}, 1000);
		setVisiable(false);
		window.scrollTo(0, 0);
	}, [type]);

	useEffect(() => {
		if (habitsDropPlace.length === 7) {
			setTimeout(() => {
				setVisiable(true);
			}, 500);
		}
	}, [habitsDropPlace]);

	return (
		<>
			<BootstrapModal
				show={modalShow}
				onHide={() => setModalShow(false)}
				type={type}
			/>

			{/* <div className="top-menu d-none d-xl-flex container-xl position-relative z-10 justify-content-end align-items-start px-0">
				<NavLink to="/" className='btn btn-primary text-nowrap mt-2'>Начать заново</NavLink>
			</div> */}

			<div className="main-wrapper">
				<div className={cn('background-wrapper')}>
					<div className={cn('background', 
						// 'container-fluid', 
						{
							'background-good': type === 'good-habits',
							'background-bad': type === 'bad-habits',
						})}
					></div>
				</div>

				<div className="container-xl position-fixed end-0 start-0 z-100">
					<NavLink to="/" className={cn('logo')}>
						<img
							src={logo}
							alt=""
						/>
					</NavLink>
					<div className="position-absolute top-0 end-0 mt-2">
						{/* <NavLink to='/rating/' className='btn btn-primary text-nowrap me-2'>Рейтинг</NavLink> */}
						<NavLink to="/" className='btn btn-primary text-nowrap me-2'>Начать заново</NavLink>
					</div>
				</div>

				<div className="container-xl position-relative">
					{/* <NavLink
						to="/"
						style={{zIndex: 12}}
						className='d-xl-none btn btn-primary position-absolute top-0 end-0 z-10 text-nowrap'
					>
						Начать заново
					</NavLink> */}
					<div className={cn('row g-0', 'row-main')}>
						<DragDropContext onDragEnd={handleOnDragEnd}>
							<div className={cn('left-side', 'col-xl-6')}>
								<div className={cn('inner')}>
									<div className={cn(type, 'image-container')}>
										<img
											className={cn('left-image', {
												'bright-image': type === 'bad-habits' && flash,
											})}
											src={type === 'good-habits' ? christmasTree : firePlace}
											alt=""
										/>
										{type === 'good-habits' &&
											toys.map((item) =>
												<img key={item.id} style={item.styles} src={item.treeToy} alt='' />)
										}
										{type === 'bad-habits' &&
											<Flame flash={flash} />
										}
										{
											habitsDropPlace?.length < 7 &&
											<div className={cn('counter', 'shadow')}>
												Осталось добавить привычек: {7 - habitsDropPlace.length}
											</div>
										}
										<DropArea
											droppableId='habitsDropPlace'
											dropableClass='drop-to'
											listClass={type === 'good-habits' ? 'drop-area-tree' : 'drop-area-fireplace'}
											/>
									</div>
								</div>
								<div className={cn('tags')}>
									{habitsDropPlace?.map((item) =>
										<span
											key={item.id}
											className={cn('tags-item')}
										>
											{`#${item.text}`}
										</span>
									)}
								</div>
							</div>

							<div className={cn('right-side', 'col-xl-6 flex-grow-1')}>
								<div className={cn('content')}>
									{
										habitsDropPlace.length < 7 &&
											<div className={cn('content-wrapper', 'shadow')}>
												<div className={cn('content-header',
													{
														'content-title-good': type === 'good-habits',
														'content-title-bad': type === 'bad-habits',
													})}
												>
													<span className='fs-4'>
														{type === 'good-habits' && chooseHabitsText.goodHabitsTitle}
														{type === 'bad-habits' && chooseHabitsText.badHabitsTitle}
													</span>
													<span
															style={{transform: 'translateY(-3px)'}}
															className='d-inline-block lh-1 text-white ms-2 position-relative'
															onClick={() => setModalShow(true)}
														>
															<svg width="28" height="28" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#ffffff" fill="none" strokeLinecap="round" strokeLinejoin="round">
																<path stroke="none" d="M0 0h24v24H0z" fill="none"/>
																<circle cx="12" cy="12" r="9" />
																<line x1="12" y1="8" x2="12.01" y2="8" />
																<polyline points="11 12 12 12 12 16 13 16" />
															</svg>
													</span>
												</div>
												
												<div>
													<div className={cn('swiper-container', 'd-block', 'd-xl-none')}>
														{
															habitsList?.map((item) => {
																return (
																	<SwipeableViews
																		key={item.id}
																		enableMouseEvents
																		axis="x"
																		onChangeIndex={() => handleOnSwipe(item)}
																	>
																		<div className={cn('swiper-habit')}>
																			<div className={cn('swiper-habit-icon')}>
																				<img 
																					src={type === 'good-habits' ? ball : trash} 
																					alt="" 
																				/>
																			</div>
																			<div>
																				{item.text}
																			</div>
																		</div>
																		<div className={cn('swiper-habit-tail')}>
																			<div className={cn('swiper-habit-tail-text')}>{item.text}</div>
																			<div className={cn({
																				'swiper-habit-tail-bad-icon': type === 'bad-habits',
																				'swiper-habit-tail-good-icon': type === 'good-habits',
																				})}
																			>
																				{
																					type === 'bad-habits' &&
																					<svg className={cn('')} version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
																						width="30px" height="38px" viewBox="0 0 125 189.864" enableBackground="new 0 0 125 189.864" xmlSpace="preserve">
																						<path className="flame-main" fill="#F36E21" d="M76.553,186.09c0,0-10.178-2.976-15.325-8.226s-9.278-16.82-9.278-16.82s-0.241-6.647-4.136-18.465
																				c0,0,3.357,4.969,5.103,9.938c0,0-5.305-21.086,1.712-30.418c7.017-9.333,0.571-35.654-2.25-37.534c0,0,13.07,5.64,19.875,47.54
																				c6.806,41.899,16.831,45.301,6.088,53.985"/>
																						<path className="flame-main one" fill="#F6891F" d="M61.693,122.257c4.117-15.4,12.097-14.487-11.589-60.872c0,0,32.016,10.223,52.601,63.123
																				c20.585,52.899-19.848,61.045-19.643,61.582c0.206,0.537-19.401-0.269-14.835-18.532S57.576,137.656,61.693,122.257z"/>
																						<path className="flame-main two" fill="#FFD04A" d="M81.657,79.192c0,0,11.549,24.845,3.626,40.02c-7.924,15.175-21.126,41.899-0.425,64.998
																				C84.858,184.21,125.705,150.905,81.657,79.192z"/>
																						<path className="flame-main three" fill="#FDBA16" d="M99.92,101.754c0,0-23.208,47.027-12.043,80.072c0,0,32.741-16.073,20.108-45.79
																				C95.354,106.319,99.92,114.108,99.92,101.754z"/>
																						<path className="flame-main four" fill="#F36E21" d="M103.143,105.917c0,0,8.927,30.753-1.043,46.868c-9.969,16.115-14.799,29.041-14.799,29.041
																				S134.387,164.603,103.143,105.917z"/>
																						<path className="flame-main five" fill="#FDBA16" d="M62.049,104.171c0,0-15.645,67.588,10.529,77.655C98.753,191.894,69.033,130.761,62.049,104.171z" />
																						<path className="flame" fill="#F36E21" d="M101.011,112.926c0,0,8.973,10.519,4.556,16.543C99.37,129.735,106.752,117.406,101.011,112.926z" />
																						<path className="flame one" fill="#F36E21" d="M55.592,126.854c0,0-3.819,13.29,2.699,16.945C64.038,141.48,55.907,132.263,55.592,126.854z" />
																						<path className="flame two" fill="#F36E21" d="M54.918,104.595c0,0-3.959,6.109-1.24,8.949C56.93,113.256,52.228,107.329,54.918,104.595z" />
																					</svg>
																				}
																				{
																					type === 'good-habits' &&
																						<img src={greenToy} alt="" />
																				}
																			</div>
																		</div>
																	</SwipeableViews>
																)
															})
														}
													</div>
													<div className='d-none d-xl-block'>
														<DropArea
															droppableId='habitsList'
															dropableClass='drop-from'
															listClass='habits-list'
															icon={type === 'good-habits' ? ball : trash}
															habitsList={habitsList}
															habitsDropPlace={habitsDropPlace}
															returnHabitToList={returnHabitToList}
														/>
													</div>
												</div>
											</div>
									}
								</div>
							</div>
						</DragDropContext>
					</div>
				</div>
				{
					visiable &&
					<Modal type={type} habitsDropPlace={habitsDropPlace} />
				}
				
			</div>
		</>
	)
}

export default ChooseHabitsPage;
