import { useEffect } from 'react';
import './App.scss';
import { Routes, Route } from "react-router-dom"
import ChooseHabitsPage from './pages/ChooseHabitsPage/ChooseHabitsPage';
import ChosenHabitsList from './pages/ChosenHabitsPage/ChosenHabitsList';
import StartPage from './pages/StartPage/StartPage';
import Snowfall from 'react-snowfall';
import Rating from './pages/Rating/Rating';
import { useDispatch } from 'react-redux';
import { fetchUserHabits } from './store/habitsSlice';
import Cookies from 'js-cookie';
import Pdf from './pages/Pdf/Pdf';
import Chart from './pages/Chart/Chart';

function App() {

	const dispatch = useDispatch();

	useEffect(() => {
		if (Cookies.get('uuid')) dispatch(fetchUserHabits());
	}, []);

	return (
		<div className='app'>
			<Routes>
				<Route path='/' element={<StartPage />} />
				<Route path='/bad-habits' element={<ChooseHabitsPage type='bad-habits' />} />
				<Route path='/good-habits' element={<ChooseHabitsPage type='good-habits' />} />
				<Route path='/chosen-habits' element={<ChosenHabitsList />} />
				<Route path='/rating' element={<Rating />} />
				<Route path='/chart' element={<Chart />} />
				<Route path='/pdf' element={<Pdf />} />
			</Routes>
			<Snowfall style={{ zIndex: 100 }} color="#fff" snowflakeCount={50} />
		</div>
	);
}

export default App;
