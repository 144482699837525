import classNames from 'classnames/bind';
import styles from './StartPage.module.scss';
import { startPageText } from '../../data/siteText';
import Cookies from 'js-cookie';
import logo from '../../assets/img/logo.svg';
import { NavLink } from 'react-router-dom';

const cn = classNames.bind(styles);

const StartPage = () => {

	const clearAll = () => {
		if (Cookies.get('uuid')) {
			Cookies.remove('uuid');
		}
	}

	return (
		<div className={cn('wrapper')}>
			<div className="container">
				<NavLink to="/" className={cn('logo')}>
					<img src={logo} alt="" />
				</NavLink>
				<div className={cn('content-window', 'rounded-3')}>
					<h2 className="fs-2 fw-normal text-center mb-4">{startPageText.title}</h2>
					<ul className="list-unstyled text-center mb-4">
						<li className={cn('list-item')}>{startPageText.firstParagraph}</li>
						<li className={cn('list-item')}>{startPageText.secondParagraph}</li>
						<li className={cn('list-item')}>{startPageText.thirdParagraph}</li>
					</ul>
					<a
						className="btn btn-lg btn-primary"
						onClick={() => clearAll()}
						href="/bad-habits/"
					>
						Начать игру
					</a>
				</div>
			</div>
		</div>
	)
}

export default StartPage;
