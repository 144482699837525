import { useEffect } from 'react';
import '../../../App.scss';
import classNames from 'classnames/bind';
import styles from './BootstrapModal.module.scss';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { chooseHabitsText, emailModalText } from './../../../data/siteText';
import { NavLink } from 'react-router-dom';
import dnd from '../../../assets/img/dnd.png';
import swipe from '../../../assets/img/swipe.png';
import { useDispatch } from 'react-redux';
import { setRating } from '../../../store/habitsSlice';
import ReactStars from 'react-stars';

const cn = classNames.bind(styles);

const BootstrapModal = (props) => {

	const dispatch = useDispatch();

	useEffect(() => {
		document.getElementById('desc-pic')?.setAttribute('src', `${dnd}`)
		document.getElementById('mobile-pic')?.setAttribute('src', `${swipe}`)
	}, [props.type, props.show])

	const ratingChanged = async (newRating) => {
		window._mtm = window._mtm || [];
		window._mtm.push({ 'event': 'add_project_grade', 'project': 'new2023', 'grade': newRating });
		dispatch(setRating(newRating));
	};

	return (
		<Modal
			{...props}
			size="lg"
			aria-labelledby="contained-modal-title-vcenter"
			// centered
		>
			{
				props.type === 'rating' && <Modal.Header closeButton />
			}
			<Modal.Body>
				<h2 className={cn('text-center', 'content-title', {
					'content-title-good': props.type === 'good-habits',
					'content-title-bad': props.type === 'bad-habits',
					'content-title-rating': props.type === 'rating',
				})}>
					{props.type === 'good-habits' && chooseHabitsText.goodHabitsTitle}
					{props.type === 'bad-habits' && chooseHabitsText.badHabitsTitle}
					{props.type === 'email' && emailModalText.text}
					{props.type === 'rating' &&
						<>
						<div className={cn('mb-3')}>Оценить проект</div>
						<ReactStars
							className={cn('stars')}
							count={5}
							onChange={ratingChanged}
							size={50}
							half={false}
							color2={'#2A9354'}
						/>
						<div className={cn('mt-2')}>Спасибо!</div>
						</>
					}
				</h2>
				{
					(props.type === 'bad-habits' || props.type === 'good-habits') &&
					<>
						<div
							className={cn('d-none', 'd-xl-block', 'text-center', 'content-description', {
								'content-description-good': props.type === 'good-habits',
								'content-description-bad': props.type === 'bad-habits',
							})}
							dangerouslySetInnerHTML={{
								__html: props.type === 'good-habits'
									? chooseHabitsText.goodHabitsDesctopDescription
									: chooseHabitsText.badHabitsDesctopDescription
							}}
						/>
						<div
							className={cn('d-block', 'd-xl-none', 'text-center', 'content-description', {
								'content-description-good': props.type === 'good-habits',
								'content-description-bad': props.type === 'bad-habits',
							})}
							dangerouslySetInnerHTML={{
								__html: props.type === 'good-habits'
									? chooseHabitsText.goodHabitsMobileDescription
									: chooseHabitsText.badHabitsMobileDescription
							}}
						/>
					</>
				}
				{
					props.type === 'email' &&
					<div
						className={cn('text-center', 'content-description')}
						dangerouslySetInnerHTML={{
							__html: emailModalText.modalText,
						}}
					/>
				}
			</Modal.Body>
			<Modal.Footer bsPrefix={cn('footer')}>
				{
					props.type === 'email' &&
					<NavLink className="btn btn-lg btn-primary" to="/rating/">Рейтинг</NavLink>
				}
				{
					props.type !== 'email' &&
						<Button className="align-self-center" onClick={props.onHide}>
							{props.type === 'rating' ? 'Закрыть' : 'Понятно'}
						</Button>
				}
			</Modal.Footer>
		</Modal>
	);
}

export default BootstrapModal;